/* You can add global styles to this file, and also import other style files */


/* @import '~font-awesome/css/font-awesome.css';
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */


/* @import '~ag-grid-community/dist/styles/ag-theme-material.css';
@import '~ag-grid-community/dist/styles/ag-theme-blue.css';
@import '~ag-grid-community/dist/styles/ag-theme-bootstrap.css';
@import '~ag-grid-community/dist/styles/ag-theme-dark.css';
@import '~ag-grid-community/dist/styles/ag-theme-fresh.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham-dark.css'; */


/* .ng-select {
  padding-bottom: 0em !important;
} */

.reportPanel {
    background-color: blanchedalmond;
    position: relative;
    float: left;
    width: 1000px;
    overflow-y: auto;
    overflow-x: auto;
    height: 1000px;
}

.custom-scroll {
    max-height: 65vh;
    position: relative;
}

.mat-dialog-custom-panel .mat-dialog-container {
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    overflow: hidden;
}

.mat-dialog-custom-panel {
    max-width: 100vw !important;
}

.mat-tooltip {
    font-size: 14px !important;
}

.mat-dialog-content {
    max-height: 85vh !important;
}

.mat-dial1 {
    max-height: 85vh !important;
    display: block;
    /* margin: 0 -24px; */
    padding: 0 24px;
    max-height: 65vh;
    overflow: auto;
}

@media (max-device-height: 750px) {
    .mat-dialog-content {
        max-height: 78vh !important;
    }
}

@media (max-device-height: 450px) {
    .mat-dialog-content {
        max-height: 70vh !important;
    }
}


@media (max-device-width: 450px) {
    .mat-paginator-range-label {
        margin: 0 !important;
    }
}

/** for icon size **/

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.align-center-vertical {
    margin-top: auto;
    margin-bottom: auto;
}

.align-bottom-vertical {
    margin-top: auto;
    margin-bottom: 0%;
}

.ag-grid-custom {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60vh;
    width: 100%;
}


/**  ag-grid copy */

.ag-cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}


/* customisation for ag-grid pagination issue in firefox */

.ag-theme-balham .ag-paging-panel>span {
    word-wrap: normal;
}

.cell-wrap-text {
    white-space: normal !important;
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
    overflow: visible !important;
    /* not as per ag-grid example but to avoid single word division */
}

.ag-custom-row-active {
    background-color: rgba(0, 0, 0, 0.075) !important;
}

.ag-custom-row-danger {
    background-color: #f5c6cb !important;
}

.ag-custom-row-default {
    background-color: #f8f9fa !important;
}

.ag-custom-row-primary {
    background-color: #b8daff !important;
}

.ag-custom-row-secondary {
    background-color: #d6d8db !important;
}

.ag-custom-row-success {
    background-color: #c3e6cb !important;
}

.ag-custom-row-warning {
    background-color: #ffeeba !important;
}

.ag-custom-row-info {
    background-color: #bee5eb !important;
}

.ag-custom-row-light {
    background-color: #fdfdfe !important;
}

.ag-custom-row-dark {
    background-color: #c6c8ca !important;
}

.ag-row-selected {
    background-color: #bde2e5 !important;
}

.ag-custom-row-green {
    background-color: #b0dfb6 !important;
}

.ag-custom-row-red {
    background-color: #e4a3a7 !important;
}


/** Loader */


/* Absolute Center Spinner */

.sk {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}


/* Transparent Overlay */

.sk:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
}

.sk-cube-grid {
    width: 40px;
    height: 40px;
    /* margin: 100px auto; */
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

.tootlbar-icon {
    padding: 0 14px;
}

.tootlbar-spacer {
    flex: 1 1 auto;
}


/** cube start **/

.sk-folding-cube {
    margin: -50px auto;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {
    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}


/* cube end */


/** Spinner start */

.spinner {
    margin: -50px auto 0;
    width: 70px;
    text-align: center;
}

.spinner>div {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}


/** Spinner end */


/** for ref ../**
  /* Absolute Center Spinner */

.loading {
    position: fixed;
    z-index: 1000;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


/* Transparent Overlay */

.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: "";
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.manage-description-deactivate {
    height: 25px;
    width: 69px;
    background-color: #f8c6c6;
    border: 1px solid;
    margin: 2px;
    padding-top: 0px;
}

.manage-description-activate {
    height: 25px;
    width: 54px;
    background-color: #ffffff;
    border: 1px solid;
    margin: 2px;
    padding-top: 0px;
}

.manage-description-locked {
    height: 25px;
    width: 54px;
    background-color: #c6c8ca;
    border: 1px solid;
    margin: 2px;
    padding-top: 0px;
}

.dateheader {
    margin-top: 28px;
    margin-bottom: -15px;
}

.cardheader {
    margin-top: -28px;
}

.manageView {
    padding-top: 7px;
}

.manage-description-success {
    height: auto;
    width: auto;
    background-color: #b0dfb6;
    border: 1px solid;
    margin: 2px;
}

.manage-description-faliure {
    height: auto;
    width: auto;
    background-color: #e4a3a7;
    border: 1px solid;
    margin: 2px;
}

.mat-nested-tree-node {
    color: #00bcd4;
}

.icon-normal {
    transform: scale(1.1);
}

.icon-medium {
    transform: scale(1.2);
}

.icon-large {
    transform: scale(1.3);
}

.button-Padding {
    padding: .5% !important;
}
.print-button {
  float: right;
  color: green;
  background: 0 0;
  border: none;
}

div[data-notify="container"].alert {
  z-index: 99999 !important;
}
